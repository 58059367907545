.full-screen {
    position: fixed !important;
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    z-index: 999;
    background-color: white;
}

.card-style {
    width: 100%;
    min-height: 450px;
    // max-height: 450px;
    margin-bottom: $distanceComponent;
    @include border-default;

    &:hover {
        @include box-shadow-default;
    }

    .ant-card-head {
        padding: 0 10px;
        min-height: 24px;
        font-size: 14px;

        .ant-card-head-wrapper {
            .ant-card-head-title {
                padding: 6px 0;
            }
        }
    }

    .ant-card-body {
        padding: 12px;
    }

    .card-custom-body {
        min-height: 350px;
        position: relative;
    }

    .card-custom-body.height-350 {
        overflow-y: auto;
        max-height: 360px !important;
    }

    .card-custom-body.height-400 {
        overflow-y: auto;
        max-height: 400px !important;
    }

    .card-custom-body.height-450 {
        overflow-y: auto;
        max-height: 450px !important;
    }

    .card-custom-body.height-500 {
        overflow-y: auto;
        max-height: 500px !important;
    }

    .card-custom-body.height-550 {
        overflow-y: auto;
        max-height: 550px !important;
    }

    .card-custom-body.height-600 {
        overflow-y: auto;
        max-height: 600px !important;
    }

    .card-custom-body.full-width {
        margin-left: -12px;
        margin-right: -12px;
    }

    .card-custom-body.full-height {
        margin-top: -12px;
        margin-bottom: -12px;
    }

    .card-custom-title {
        align-items: baseline;
        white-space: nowrap;
        display: flex;

        .card-information {
            width: 100%;
            @include hide-text-overflow;
        }

        @import "ToolboxControl/style";
    }
}

.card-custom {
    width: 100%;
    margin-bottom: $distanceComponent;
    @include border-default;

    &:hover {
        @include box-shadow-default;
    }

    .ant-card-head {
        padding: 0 10px;
        min-height: 24px;
        font-size: 14px;

        .ant-card-head-wrapper {
            .ant-card-head-title {
                padding: 6px 0;
            }
        }
    }

    .ant-card-body {
        padding: 12px;
    }

    .card-custom-body {
        min-height: 350px;
        position: relative;
    }

    .card-custom-body.height-350 {
        overflow-y: auto;
        max-height: 350px !important;
    }

    .card-custom-body.height-400 {
        overflow-y: auto;
        max-height: 400px !important;
    }

    .card-custom-body.height-450 {
        overflow-y: auto;
        max-height: 450px !important;
    }

    .card-custom-body.height-500 {
        overflow-y: auto;
        max-height: 500px !important;
    }

    .card-custom-body.height-550 {
        overflow-y: auto;
        max-height: 550px !important;
    }

    .card-custom-body.height-600 {
        overflow-y: auto;
        max-height: 600px !important;
    }

    .card-custom-body.full-width {
        margin-left: -12px;
        margin-right: -12px;
    }

    .card-custom-body.full-height {
        margin-top: -12px;
        margin-bottom: -12px;
    }

    .card-custom-title {
        align-items: baseline;
        white-space: nowrap;
        display: flex;

        .card-information {
            width: 100%;
            @include hide-text-overflow;
        }

        @import "ToolboxControl/style";
    }
}

.card-custom.full-screen {
    .ant-card-body {
        height: 100%;
    }

    .card-custom-body {
        height: 100%;
    }

    .card-custom-body.height-350 {
        overflow-y: auto;
        max-height: unset;
    }
}

.custom-blocked {
    opacity: 0.4;
    pointer-events: none;
}